const G02SpreadInfo = {
  sheetName: 'G02',
  headerRowCount: 2,
  headerColumnCount: 16,
  headerColumns: [
    {
      index: 0,
      name: '序号',
      width: '110'
    },
    {
      index: 1,
      name: '工程名称',
      width: '100',
      children: [
        {
          index: 1,
          name: '工程类别',
          width: '150'
        },
        {
          index: 2,
          name: '工程名称',
          width: '200'
        }
      ]
    },
    {
      index: 3,
      name: '单位',
      width: '60'
    },
    {
      index: 4,
      name: '合同数量',
      children: [
        {
          index: 4,
          name: '原合同数量',
          width: '90'
        },
        {
          index: 5,
          name: '按施工图调整后数量',
          width: '90'
        },
        {
          index: 6,
          name: '变更数量',
          width: '90'
        },
        {
          index: 7,
          name: '变更后数量',
          width: '90'
        }
      ]
    },
    {
      index: 8,
      name: '本年计划',
      children: [
        {
          index: 8,
          name: '工程量',
          width: '90'
        }
      ]
    },
    {
      index: 9,
      name: '本月计划',
      children: [
        {
          index: 9,
          name: '工程量',
          width: '90'
        }
      ]
    },
    {
      index: 10,
      name: '本月完成',
      children: [
        {
          index: 10,
          name: '工程量',
          width: '90'
        },
        {
          index: 11,
          name: '占月计划%',
          width: '90'
        }
      ]
    },
    {
      index: 12,
      name: '本年完成',
      children: [
        {
          index: 12,
          name: '工程量',
          width: '90'
        },
        {
          index: 13,
          name: '占年计划%',
          width: '90'
        }
      ]
    },
    {
      index: 14,
      name: '全部工程累计完成',
      children: [
        {
          index: 14,
          name: '工程量',
          width: '90'
        },
        {
          index: 15,
          name: '占合同%',
          width: '90'
        }
      ]
    }
  ]
}

export default G02SpreadInfo
