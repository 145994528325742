<template>
  <div class="g02">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
          <el-button v-if="errorKeys.length" type="primary" @click="handleErrorClick">下一错误</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div ref="spreadContainer" class="spread-container">
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import SpreadMixin from '@/mixins/SpreadMixin'
import auth from '@/common/auth'
import g26Service from '@/services/g26Service'
import g02Service from '@/services/g02Service'
import G02SpreadInfo from '@/views/PlanStatManage/HeaderColumns/G02SpreadInfo'
import G02Model from '@/model/G02Model'
import utility from '@/common/utility'
import { mapGetters } from 'vuex'

export default {
  name: 'g02',
  mixins: [
    SpreadMixin
  ],
  components: {
    MySearchBar
  },
  data () {
    return {
      engineeringList: [],
      engineeringListMap: {},
      g02List: [],
      spreadInfo: G02SpreadInfo,
      currentErrorIndex: 0
    }
  },
  computed: {
    errorKeys () {
      return Object.keys(this.errorMap)
    },
    tenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      month: 'getMonth',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSaveClick () {
      // 检查是否有错误
      if (Object.keys(this.errorMap).length) {
        this.$message({
          type: 'error',
          message: '数据存在问题，请修正后再保存！'
        })
        return
      }
      this.translate()
      this.pushData()
    },
    handleErrorClick () {
      let rowIndex = this.errorMap[this.errorKeys[this.currentErrorIndex]].rowIndex
      this.worksheet.showRow(rowIndex, GC.Spread.Sheets.VerticalPosition.top)
      this.currentErrorIndex = (this.currentErrorIndex + 1) % this.errorKeys.length
    },
    setProtection () {
      this.worksheet.options.isProtected = true
      let unLockStyle = new GC.Spread.Sheets.Style()
      unLockStyle.backColor = '#aea'
      unLockStyle.locked = false

      this.worksheet.setStyle(-1, 10, unLockStyle)
    },
    bindCellChanged () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ValueChanged, (sender, info) => {
        this.rowCompute(info.row)
      })
    },
    bindDragFillBlockCompleted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (e, info) => {
        for (let i = 0; i < info.fillRange.rowCount; ++i) {
          this.rowCompute(info.fillRange.row + i)
        }
      })
    },
    bindClipboardPasted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, (sender, info) => {
        for (let i = 0; i < info.cellRange.rowCount; ++i) {
          this.rowCompute(i + info.cellRange.row)
        }
      })
    },
    rowCompute (rowIndex) {
      let currentMonthNum = this.worksheet.getValue(rowIndex, 10)

      let g26 = this.engineeringListMap[parseInt(rowIndex)]
      let currentMonthPercent
      let flag = false

      if (currentMonthNum) {
        flag = true
      }

      if (utility.isNumber(currentMonthNum)) {
        if (g26.currentMonthNum === 0) {
          currentMonthPercent = currentMonthNum
        } else {
          currentMonthPercent = Math.round(10000 * utility.floatDivide(currentMonthNum, g26.currentMonthNum)) / 100
        }

        let currentYearCompleteNum = utility.floatAdd(currentMonthNum, g26.currentYearAggregationNum)
        let currentYearCompleteYearPercent
        if (g26.currentYearNum === 0) {
          currentYearCompleteYearPercent = currentYearCompleteNum
        } else {
          currentYearCompleteYearPercent = Math.round(10000 * utility.floatDivide(currentYearCompleteNum, g26.currentYearNum)) / 100
        }
        let totalCompleteNum = utility.floatAdd(currentMonthNum, g26.totalAggregationNum)
        let totalCompletePercent = Math.round(10000 * utility.floatDivide(totalCompleteNum, g26.engineeringList.num)) / 100

        this.worksheet.setValue(rowIndex, 11, currentMonthPercent)
        this.worksheet.setValue(rowIndex, 12, currentYearCompleteNum)
        this.worksheet.setValue(rowIndex, 13, currentYearCompleteYearPercent)
        this.worksheet.setValue(rowIndex, 14, totalCompleteNum)
        this.worksheet.setValue(rowIndex, 15, totalCompletePercent)
      } else {
        this.worksheet.setValue(rowIndex, 11, '')
        this.worksheet.setValue(rowIndex, 12, '')
        this.worksheet.setValue(rowIndex, 13, '')
        this.worksheet.setValue(rowIndex, 14, '')
        this.worksheet.setValue(rowIndex, 15, '')
      }

      if (flag && !utility.isNumber(currentMonthNum)) {
        this.setError('请检查数据是否填写正确', rowIndex, 11)
      } else {
        this.setError(null, rowIndex, 11)
      }
      this.currentErrorIndex = 0
    },
    translate () {
      let dataTable = this.spread.toJSON({
        ignoreStyle: true
      }).sheets[this.spreadInfo.sheetName].data.dataTable
      console.log(dataTable)

      this.g02List = []
      for (let key in Object.keys(dataTable)) {
        let item = dataTable[key]

        if (!item[10] || !utility.isNumber(item[10].value)) {
          continue
        }

        let g02Model = new G02Model({ planStatExaminationGuid: this.planStatExaminationGuid, engineeringListGuid: this.engineeringListMap[parseInt(key)].engineeringListGuid, tenderGuid: auth.getUserInfo().tenderGuid, year: this.year, month: this.month })
        g02Model.g26Guid = this.engineeringListMap[g02Model.engineeringListGuid].g26Guid
        g02Model.currentMonthNum = parseFloat(item[10].value)
        g02Model.currentMonthPercent = parseFloat(item[11].value)
        g02Model.totalMonthNum = parseFloat(item[12].value)
        g02Model.totalMonthPercent = parseFloat(item[13].value)
        g02Model.totalNum = parseFloat(item[14].value)
        g02Model.totalPercent = parseFloat(item[15].value)
        g02Model.init()

        if (utility.isNumber(g02Model.currentMonthNum)) {
          this.g02List.push(g02Model)
        }
      }
    },
    pushData () {
      g02Service.batchAdd(this.g02List)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
            this.$router.push('/g02Index')
          } else {
            if (res.data.data.length) {
              res.data.data.forEach(item => {
                this.setError('请检查数据是否填写正确', this.engineeringListMap[item.engineeringListGuid].index, 11)
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    bindEngineeringList () {
      // 设定表格的行数
      this.worksheet.setRowCount(this.engineeringList.length)
      let dataArray = []
      // 绑定数据
      this.engineeringList.forEach((item, index) => {
        let rowData = []
        rowData.push(index + 1)
        rowData.push(item.parentEngineeringList.engineeringListName)
        rowData.push(item.engineeringList.engineeringListName)
        rowData.push(item.engineeringList.unit)
        rowData.push(item.engineeringList.num)
        rowData.push(item.engineeringList.num)
        rowData.push('')
        rowData.push(item.engineeringList.num)
        rowData.push(item.currentYearNum)
        rowData.push(item.currentMonthNum)
        for (let i = 0; i < 6; ++i) {
          rowData.push('')
        }
        dataArray.push(rowData)

        // 附带算一下engineeringListMap
        item.index = index
        this.engineeringListMap[index] = item
        this.engineeringListMap[item.engineeringListGuid] = item
      })
      this.worksheet.setArray(0, 0, dataArray)
    },
    getG02List () {
      g02Service.list({ tenderGuid: this.tenderGuid, planStatExaminationGuid: this.planStatExaminationGuid })
        .then(res => {
          this.getEngineeringList(res.data.data.map(item => item.engineeringListGuid))
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    getEngineeringList (exceptData) {
      if (!exceptData) {
        exceptData = []
      }
      g26Service.aggregation(this.tenderGuid, this.year, this.month)
        .then(res => {
          if (res.data.code === 1) {
            this.engineeringList = res.data.data.filter(item => {
              if (exceptData.indexOf(item.engineeringListGuid) !== -1) {
                return false
              }
              return true
            })

            this.bindEngineeringList()
            this.setProtection()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  mounted () {
    this.initWorkbook(this.$refs.spreadContainer)
    this.getG02List()
    this.bindCellChanged()
    this.bindClipboardPasted()
    this.bindDragFillBlockCompleted()
  }
}
</script>

<style scoped lang="scss">
.g02 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-container {
    flex: 1;
  }
}
</style>
